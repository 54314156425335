import { ReviewsStars } from "app/home/Reviews/TrustPilotReview/ReviewsStars";
import { Card } from "ui/Card";

type TrustPilotReviewProps = {
  author: string;
  rating: number;
  comment: string;
  date: string;
};

export const TrustPilotReview = ({
  author,
  rating,
  comment,
  date,
}: TrustPilotReviewProps) => {
  return (
    <Card
      border="filled"
      className="flex flex-col justify-evenly px-6 m-2 w-80 h-56 bg-white rounded-2xl"
    >
      <p className="text-lg font-semibold leading-snug">{author}</p>
      <p className="overflow-hidden h-1/3 font-normal text-ellipsis">
        {comment}
      </p>

      <ReviewsStars rating={rating} />
      <p className="text-xs font-normal opacity-60 lg:text-base">{date}</p>
    </Card>
  );
};
