import { fetchCollection } from "utils/strapi/api-client";
import { CollectionPage, SinglePage } from "utils/strapi/collection";
import { DISABLED_PAGINATION_PARAMS } from "utils/strapi/pagination";
import { Response, ResponseData } from "utils/strapi/response";

import { mapSeries } from "./map-series.utils";

const formatResponse = (responses: Response<ResponseData | ResponseData[]>[]) =>
  responses.map(({ data }) =>
    Array.isArray(data)
      ? data.map(({ attributes }) => attributes)
      : data.attributes,
  );

export const fetchPages = async <TOutput = any[]>(
  pages: SinglePage | CollectionPage | Array<SinglePage | CollectionPage>,
) => {
  const pagesArray = Array.isArray(pages) ? pages : [pages];

  const collections = await mapSeries(pagesArray, (page) =>
    fetchCollection({
      collection: page,
      pagination: DISABLED_PAGINATION_PARAMS,
    }),
  );

  const formattedCollections = formatResponse(collections);

  return formattedCollections as unknown as TOutput;
};
