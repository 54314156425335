import { StaticImageData } from "next/image";

import { StaticImage } from "ui/StaticImage";
import { useI18n } from "utils/with-i18n.utils";

export type CarouselExplanationProps = {
  description: string;
  src: StaticImageData;
  title: string;
};

export const CarouselExplanation = ({
  description,
  src,
  title,
}: CarouselExplanationProps) => {
  const i18n = useI18n();

  return (
    <div className="text-center">
      <div className="relative m-auto h-[350px]">
        <StaticImage
          alt="carousel card image"
          src={src}
          layout="fill"
          placeholder="blur"
          objectFit="contain"
        />
      </div>
      <p className="mt-20 mb-3 text-3xl font-bold">{i18n.t(title)}</p>
      <p>{i18n.t(description)}</p>
    </div>
  );
};
