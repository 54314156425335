import * as Types from '../../graphql.d';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type WebsiteSettingsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type WebsiteSettingsQuery = { __typename?: 'query_root', websiteSetting?: Types.Maybe<{ __typename?: 'WebsiteSettingEntityResponse', data?: Types.Maybe<{ __typename?: 'WebsiteSettingEntity', id?: Types.Maybe<string>, attributes?: Types.Maybe<{ __typename?: 'WebsiteSetting', isBlackFriday?: Types.Maybe<boolean> }> }> }> };


export const WebsiteSettingsDocument = gql`
    query websiteSettings {
  websiteSetting {
    data {
      id
      attributes {
        isBlackFriday
      }
    }
  }
}
    `;
export function useWebsiteSettingsQuery(baseOptions?: Apollo.QueryHookOptions<WebsiteSettingsQuery, WebsiteSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WebsiteSettingsQuery, WebsiteSettingsQueryVariables>(WebsiteSettingsDocument, options);
      }
export function useWebsiteSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WebsiteSettingsQuery, WebsiteSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WebsiteSettingsQuery, WebsiteSettingsQueryVariables>(WebsiteSettingsDocument, options);
        }
export type WebsiteSettingsQueryHookResult = ReturnType<typeof useWebsiteSettingsQuery>;
export type WebsiteSettingsLazyQueryHookResult = ReturnType<typeof useWebsiteSettingsLazyQuery>;
export type WebsiteSettingsQueryResult = Apollo.QueryResult<WebsiteSettingsQuery, WebsiteSettingsQueryVariables>;