import cx from "classnames";
import React from "react";

export type VerticalSeparatorProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLHRElement>,
  HTMLHRElement
>;

export const VerticalSeparator = ({
  className,
  ...props
}: VerticalSeparatorProps) => (
  <div className={cx("w-0 border-l border-gray", className)} {...props} />
);
