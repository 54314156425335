import { ReactNode } from "react";

export type BenefitCardProps = {
  title: ReactNode;
  description: ReactNode;
  icon: string;
  iconAlt: string;
};

export const BenefitCard = ({
  title,
  description,
  icon,
  iconAlt,
}: BenefitCardProps) => {
  return (
    <div
      className="p-0.5 rounded-xl lg:max-w-[450px]"
      style={{
        background:
          "linear-gradient(171.86deg, #FFF95F -29.95%, #F5B8D2 46.15%, #7D89FF 122.25%)",
      }}
    >
      <section className="flex flex-col gap-3 py-6 pr-14 pl-[72px] bg-white rounded-xl">
        <div className="relative">
          <h3 className="text-[20px] font-bold md:text-3xl md:leading-[41px]">
            {title}
          </h3>
          <img className="absolute top-2 -left-12" src={icon} alt={iconAlt} />
        </div>
        <p className="leading-[22px] md:text-lg md:leading-7">{description}</p>
      </section>
    </div>
  );
};
