import includes from "lodash/includes";
import uniq from "lodash/uniq";

import {
  BestSellersCatalogDocument,
  BestSellersCatalogQuery,
} from "shared/catalog/BestSellersCatalog/best-sellers-catalog.query.generated";
import { applyTax } from "utils/price.utils";
import { initializeApollo } from "utils/with-apollo.utils";

export type BestSellersEntries = Awaited<
  ReturnType<typeof getBestSellerEntries>
>;
export const getBestSellerEntries = async () => {
  const apolloClient = initializeApollo();

  const { data } = await apolloClient.query<BestSellersCatalogQuery>({
    query: BestSellersCatalogDocument,
  });

  const isBlackFriday =
    data?.websiteSetting?.data?.attributes?.isBlackFriday ?? false;

  return data.bestSellersCatalog?.data?.attributes?.entires?.map((entry) => {
    const subscriptions = entry?.subscriptions?.data;

    const isOutOfStock = subscriptions?.every(
      (subscription) =>
        !(subscription?.attributes?.subscriptionItem?.stock?.isOnline ?? false),
    );

    const availableModels = subscriptions?.map(
      (subscription) =>
        subscription?.attributes?.subscriptionItem?.properties?.model,
    );

    const availableUpfronts = data?.upfronts?.filter(({ properties }) =>
      includes(availableModels, properties?.model),
    );

    const minimumSubscriptionPrice = Math.min(
      ...(subscriptions
        ?.map((subscription) => subscription?.attributes?.subscriptionItem)
        ?.map((subscriptionItem) =>
          applyTax(
            subscriptionItem?.priceExcludingTax ?? undefined,
            subscriptionItem?.tax?.amount,
          ),
        ) ?? [0]),
    );
    const minimumUpfrontPrice = Math.min(
      ...availableUpfronts?.map(({ priceExcludingTax, tax }) =>
        applyTax(priceExcludingTax ?? undefined, tax?.amount),
      ),
    );

    const flagshipVariant =
      entry?.flagship?.data?.attributes?.smartphoneVariant?.data?.attributes;
    const flagshipPicture = flagshipVariant?.combinedPicture?.data?.attributes;
    const flagshipPictureText = flagshipPicture?.alternativeText;
    const flagshipPictureFormats = flagshipPicture?.formats;

    const picture = {
      alternativeText: flagshipPictureText ?? null,
      medium: flagshipPictureFormats?.medium?.url ?? null,
      thumbnail: flagshipPictureFormats?.thumbnail?.url ?? null,
    };

    const slug = `/${flagshipVariant?.smartphoneFamily?.data?.attributes?.slug}`;

    const availableColors = uniq(
      entry?.subscriptions?.data.map(
        ({ attributes }) =>
          attributes?.smartphoneVariant?.data?.attributes?.colorCode ?? "",
      ),
    );

    const originalSubscriptionPrice =
      entry?.subscriptionDrop?.[0]
        ?.formerSubscriptionPriceInCentsIncludingTax ?? null;

    const originalUpfrontPrice =
      (isBlackFriday
        ? entry?.blackFridayFormerUpfrontPriceInCentsInclundingTax
        : entry?.formerUpfrontPriceInCentsInclundingTax) ?? null;

    return {
      modelName: entry?.entryName ?? "",
      isNew: entry?.isNew || false,
      isOutOfStock,
      minimumSubscriptionPrice,
      minimumUpfrontPrice,
      originalUpfrontPrice,
      originalSubscriptionPrice,
      availableColors,
      picture,
      slug,
    };
  });
};
