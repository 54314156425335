import { BestSellerItem } from "shared/catalog/BestSellersCatalog/BestSellerItem";
import { useBestSellersContext } from "shared/catalog/BestSellersCatalog/BestSellersContext/useBestSellersContext";
import { CatalogLayout } from "shared/catalog/CatalogLayout";

export const BestSellersCatalog = () => {
  const bestSellersEntries = useBestSellersContext();

  return (
    <CatalogLayout>
      {bestSellersEntries
        ?.filter(Boolean)
        ?.map((item, index) => <BestSellerItem key={index} item={item} />)}
    </CatalogLayout>
  );
};
