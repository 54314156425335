import Link from "next/link";

import MobileClubLogoGroup from "assets/mobile-club-logo-group.svg";
import WhiteCrossSVG from "assets/white-cross.svg";
import YellowCheckSVG from "assets/yellow-check.svg";
import { Button } from "ui/Button";
import { Card } from "ui/Card";
import { CheckeredContainer } from "ui/CheckeredContainer";
import { useI18n } from "utils/with-i18n.utils";

import ElipsisSVG from "./elipsis.svg";

export type Review = {
  id: string;
  comment: string;
  date: string;
  rating: number;
  author: string;
};

export const RentVsBuy = () => {
  const i18n = useI18n();

  return (
    <CheckeredContainer className="flex flex-col items-center">
      <div className="px-6 my-12 text-4xl font-semibold text-center lg:my-24 lg:text-6xl">
        <h2>
          {i18n.t("homepage.rentvsbuy.title.rent")}
          <span className="italic font-normal">
            {i18n.t("homepage.rentvsbuy.title.vs")}
          </span>
          {i18n.t("homepage.rentvsbuy.title.buy")}
        </h2>

        <p className="mt-6 text-base font-normal">
          {i18n.t("homepage.rentvsbuy.subtitle")}
        </p>
      </div>
      <div className="relative w-full max-w-7xl">
        <Card className="flex flex-col gap-y-3 justify-between py-4 pl-5 bg-white rounded-none border-black lg:gap-y-6 lg:py-16 lg:pl-16 lg:rounded-3xl">
          <div className="flex justify-between items-center">
            <p className="text-xl font-bold lg:text-4xl">
              <span className="w-full">{i18n.t("homepage.args.title")}</span>
            </p>
            <p className="basis-1/6 font-normal text-center lg:basis-1/3 lg:text-2xl">
              {i18n.t("homepage.buy")}
            </p>
          </div>
          <div className="flex justify-between items-center ">
            <p className="flex w-48 lg:ml-2 lg:w-fit">
              <ElipsisSVG className="mt-2 w-6" />
              <span className="w-full">{i18n.t("homepage.args.1")}</span>
            </p>
            <div className="flex basis-1/6 justify-center items-center lg:basis-1/3">
              <WhiteCrossSVG />
            </div>
          </div>
          <div className="flex justify-between items-center">
            <p className="flex w-48 lg:ml-2 lg:w-fit">
              <ElipsisSVG className="mt-2 w-6" />
              <span className="w-full">{i18n.t("homepage.args.2")}</span>
            </p>
            <div className="flex basis-1/6 justify-center items-center lg:basis-1/3">
              <WhiteCrossSVG />
            </div>
          </div>
          <div className="flex justify-between items-center">
            <p className="flex w-48 lg:ml-2 lg:w-fit">
              <ElipsisSVG className="mt-2 w-6" />
              <span className="w-full">{i18n.t("homepage.args.3")}</span>
            </p>
            <div className="flex basis-1/6 justify-center items-center lg:basis-1/3">
              <WhiteCrossSVG />
            </div>
          </div>
          <div className="flex justify-between items-center">
            <p className="flex w-48 lg:ml-2 lg:w-fit">
              <ElipsisSVG className="mt-2 w-6" />
              <span className="w-full">{i18n.t("homepage.args.4")}</span>
            </p>
            <div className="flex basis-1/6 justify-center items-center lg:basis-1/3">
              <WhiteCrossSVG />
            </div>
          </div>
          <div className="flex justify-between items-center">
            <p className="flex w-48 lg:ml-2 lg:w-fit">
              <ElipsisSVG className="mt-2 w-6" />
              <span className="w-full">{i18n.t("homepage.args.5")}</span>
            </p>
            <div className="flex basis-1/6 justify-center items-center lg:basis-1/3">
              <WhiteCrossSVG />
            </div>
          </div>
          <div className="flex justify-between items-center">
            <p className="flex w-48 lg:ml-2 lg:w-fit">
              <ElipsisSVG className="mt-2 w-6" />
              <span className="w-full">{i18n.t("homepage.args.6")}</span>
            </p>
            <div className="flex basis-1/6 justify-center items-center lg:basis-1/3">
              <WhiteCrossSVG />
            </div>
          </div>
        </Card>
        <div className="absolute inset-x-[64%] -top-4 lg:inset-x-[44%] lg:-top-10">
          <Card
            border="filled"
            className="flex flex-col gap-y-9 items-center px-1 pt-4 w-fit bg-cosmic-latte rounded-2xl drop-shadow-none lg:gap-y-6 lg:py-12 lg:px-16 lg:drop-shadow-black"
          >
            <MobileClubLogoGroup className="hidden mb-10 lg:block" />
            <div className="flex justify-center items-center mt-6 w-16 h-0 lg:hidden">
              <MobileClubLogoGroup viewBox="0 0 110 110" />
            </div>
            <div className="flex justify-between items-center">
              <YellowCheckSVG />
            </div>
            <div className="flex justify-between items-center">
              <YellowCheckSVG />
            </div>
            <div className="flex justify-between items-center">
              <YellowCheckSVG />
            </div>
            <div className="flex justify-between items-center">
              <YellowCheckSVG />
            </div>
            <div className="flex justify-between items-center">
              <YellowCheckSVG />
            </div>
            <div className="flex justify-between items-center mb-4 lg:mb-0">
              <YellowCheckSVG />
            </div>
            <Link href="/smartphone">
              <a aria-label="choisir un telephone">
                <Button className="hidden text-lg font-medium whitespace-nowrap lg:inline-block">
                  {i18n.t("homepage.cta")}
                </Button>
              </a>
            </Link>
          </Card>
        </div>
      </div>
      <Link href="/smartphone">
        <a aria-label="choisir un telephone">
          <Button className="mt-10 text-lg font-medium lg:hidden">
            {i18n.t("homepage.cta")}
          </Button>
        </a>
      </Link>
    </CheckeredContainer>
  );
};
