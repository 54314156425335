import iphone15Picture from "images/devices-combined/iphone-15-pro-blue-titanium.webp";
import heroPictureBlackFriday from "images/home/hero-black-friday.webp";
import heroLargePictureBlackFriday from "images/home/lg-hero-black-friday.webp";
import heroPicture from "images/home/lg-hero-mobile-revolution.webp";
import heroPictureMobile from "images/home/mobile-hero-mobile-revolution.webp";
import Link from "next/link";

import { useSettings } from "shared/SettingsProvider/useSettings";
import { TrustpilotWidget } from "shared/TrustpilotWidget";
import { Button } from "ui/Button";
import { HorizontalSeparator } from "ui/HorizontalSeparator";
import { StaticImage } from "ui/StaticImage";
import { useI18n } from "utils/with-i18n.utils";

import MedalSVG from "./medal.svg";
import ParcelSVG from "./parcel.svg";
import RecyclingSVG from "./recycling.svg";
import VerifiedSVG from "./verified.svg";

export const Hero = () => {
  const { isBlackFriday } = useSettings();
  const i18n = useI18n();

  return (
    <section>
      <div className="flex flex-col grow lg:flex-row">
        <div className="lg:hidden">
          <Link href="/smartphone/nouveau">
            <a className="relative w-full h-full">
              <StaticImage
                src={isBlackFriday ? heroPictureBlackFriday : heroPictureMobile}
                alt="Decorative cover image"
                sizes="(max-width: 1024px) 100vw, 0vw"
                priority
                placeholder="blur"
                objectFit="cover"
              />
            </a>
          </Link>
        </div>
        <article className="flex basis-[48%] flex-col items-center border-r border-r-black">
          <div className="flex flex-col items-center px-8 max-w-3xl lg:items-start lg:px-16">
            <div className="my-6 text-4xl font-medium text-center lg:my-12 lg:text-7xl lg:text-left">
              {i18n.t("homepage.hero.title")}
            </div>

            <h1 className="text-lg text-center lg:text-2xl lg:text-left">
              {i18n.t("homepage.hero.price1")}

              <span className="font-semibold">
                {i18n.t("homepage.hero.price2")}
              </span>
            </h1>
            <Link href="/smartphone">
              <a>
                <Button className="mt-9 text-lg font-medium lg:mt-4">
                  <span className="hidden lg:inline-block">
                    {i18n.t("homepage.hero.ctaLarge")}
                  </span>
                  <span className="lg:hidden">
                    {i18n.t("homepage.hero.cta")}
                  </span>
                </Button>
              </a>
            </Link>

            <TrustpilotWidget className="my-12 mx-[-17px] lg:w-[422px]" />
          </div>

          <HorizontalSeparator className="w-full" isBlack />

          <div className="flex relative justify-center p-8 w-full  max-w-3xl  lg:px-16">
            <div className="w-full">
              <h2 className="mt-2 mb-1 text-xl font-semibold lg:mt-5 lg:text-3xl">
                {i18n.t("homepage.hero.iphone15.title")}
              </h2>
              <p className="mb-1 text-lg lg:mb-5 lg:text-2xl ">
                {i18n.t("homepage.hero.iphone15.description")}
              </p>
              <Link href="/smartphone/iphone-15-pro">
                <a>
                  <Button
                    alternative="secondary"
                    className="my-1 text-lg font-medium lg:my-5"
                  >
                    {i18n.t("homepage.hero.iphone15.price")}
                  </Button>
                </a>
              </Link>
            </div>
            <div className="flex relative basis-1/2 ml-4 lg:basis-1/3">
              <StaticImage
                src={iphone15Picture}
                alt="iPhone 15"
                layout="fill"
                sizes="(max-width: 1024px) 33vw, 15vw"
                placeholder="blur"
                objectFit="contain"
              />
            </div>
          </div>
        </article>
        <div className="hidden relative grow bg-slate-700 lg:flex">
          <Link href="/smartphone/nouveau">
            <a className="relative w-full h-full">
              <StaticImage
                alt="hero picture"
                src={isBlackFriday ? heroLargePictureBlackFriday : heroPicture}
                layout="fill"
                sizes="(min-width: 1024px) 50vw, 0vw"
                placeholder="blur"
                objectFit="cover"
                priority
              />
            </a>
          </Link>
        </div>
      </div>
      <div className="flex flex-wrap gap-x-10 gap-y-6 justify-center py-6 text-center bg-yellow border-y border-y-black lg:gap-x-20">
        <div className="flex flex-col items-center">
          <VerifiedSVG className="mb-3" />
          <p className="text-sm lg:text-lg">
            <span className="font-semibold">
              {i18n.t("homepage.hero.insurance")}
            </span>
            <br />
            {i18n.t("homepage.hero.insurance.description")}
          </p>
        </div>
        <div className="flex flex-col items-center">
          <ParcelSVG className="mb-3" />
          <p className="text-sm lg:text-lg">
            <span className="font-semibold">
              {i18n.t("homepage.hero.shipping")}
            </span>
            <br />
            {i18n.t("homepage.hero.shipping.description")}
          </p>
        </div>
        <div className="flex flex-col items-center">
          <MedalSVG className="mb-3" />
          <p className="text-sm lg:text-lg">
            <span className="font-semibold">
              {i18n.t("homepage.hero.warranty")}
            </span>
            <br />
            {i18n.t("homepage.hero.warranty.description")}
          </p>
        </div>
        <div className="flex flex-col items-center">
          <RecyclingSVG className="mb-3" />
          <p className="text-sm lg:text-lg">
            <span className="font-semibold">
              {i18n.t("homepage.hero.recycling")}
            </span>
            <br />
            {i18n.t("homepage.hero.recycling.description")}
          </p>
        </div>
      </div>
    </section>
  );
};
