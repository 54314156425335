import {
  WebsiteSettingsDocument,
  WebsiteSettingsQuery,
} from "shared/SettingsProvider/website-settings.query.generated";
import { initializeApollo } from "utils/with-apollo.utils";

export const fetchSettings = async () => {
  const apolloClient = initializeApollo();

  const { data } = await apolloClient.query<WebsiteSettingsQuery>({
    query: WebsiteSettingsDocument,
  });

  const isBlackFriday =
    data?.websiteSetting?.data?.attributes?.isBlackFriday ?? false;

  return { isBlackFriday };
};
