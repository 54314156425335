import { StaticImageData } from "next/image";

import { StaticImage } from "ui/StaticImage";

export type CarouselImageProps = { src: StaticImageData };

export const CarouselImage = ({ src }: CarouselImageProps) => {
  return (
    <div className="relative w-[430px] h-[560px]">
      <StaticImage
        alt="carousel card image"
        src={src}
        layout="fill"
        sizes="50vw"
        placeholder="blur"
        objectFit="contain"
      />
    </div>
  );
};
