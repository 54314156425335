import { useContext } from "react";

import { BestSellersContext } from "shared/catalog/BestSellersCatalog/BestSellersContext/index";

export const useBestSellersContext = () => {
  const bestSellersContext = useContext(BestSellersContext);

  if (bestSellersContext === undefined) {
    throw new Error(
      "useBestSellersContext must be used within BestSellersContextProvider",
    );
  }

  return bestSellersContext;
};
