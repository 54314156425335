import cx from "classnames";

import { VerticalSeparator } from "ui/VerticalSeparator";
import { useI18n } from "utils/with-i18n.utils";

type ExplanationBlockProps = {
  description: string;
  isActive: boolean;
  onSelectText: () => void;
  title: string;
};

export const ExplanationBlock = ({
  description,
  isActive,
  onSelectText,
  title,
}: ExplanationBlockProps) => {
  const i18n = useI18n();

  return (
    <details
      className="relative pl-6 mb-6 cursor-pointer"
      open={isActive}
      onClick={(event) => {
        onSelectText();
        event.preventDefault();
      }}
    >
      <VerticalSeparator className="absolute top-0 left-0 h-full border-l-4 border-yellow" />
      <summary
        className={cx(
          "pl-1 text-4xl font-semibold list-none",
          !isActive && "opacity-30",
        )}
      >
        {i18n.t(title)}
      </summary>
      <p className="mt-2 text-lg font-normal">{i18n.t(description)}</p>
    </details>
  );
};
