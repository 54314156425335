import { useEffect } from "react";

import { getCookie, removeCookie } from "utils/cookies.utils";

const COOKIE_ID = "blackfriday";

export const useRemoveBlackFridayCookie = () => {
  useEffect(() => {
    if (getCookie(COOKIE_ID)) {
      removeCookie(COOKIE_ID);
    }
  }, []);
};
