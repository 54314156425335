import { StaticImageData } from "next/image";

import { ArticlePreviewText } from "app/home/PressFooter/ArticlePreviewText";
import { StaticImage } from "ui/StaticImage";

interface NewspaperApparitionProps {
  logo: StaticImageData;
  previewText: string;
  link: string;
}

export const NewspaperApparition = ({
  logo,
  previewText,
  link,
}: NewspaperApparitionProps) => {
  return (
    <div
      className=" lg:mx-4"
      style={{
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div className="lg:mb-1">
        <StaticImage
          src={logo}
          alt={"Newspaper logo"}
          width={"250px"}
          objectFit={"contain"}
          height={"85px"}
        />
      </div>
      <ArticlePreviewText previewText={previewText} link={link} />
    </div>
  );
};
