import StarSVG from "src/app/home/Reviews/TrustPilotReview/ReviewsStars/star.svg";

type ReviewsStarsProps = { rating: number };

export const ReviewsStars = ({ rating }: ReviewsStarsProps) => {
  const roundedRating = Math.floor(rating);
  const decimal = rating - roundedRating !== 0;

  return (
    <div className="flex gap-x-1">
      {[...Array(roundedRating)].map((_, index) => (
        <StarSVG alt="star" key={index} />
      ))}
      {decimal && <StarSVG alt="half-star" />}
    </div>
  );
};
