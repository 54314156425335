import cx from "classnames";
import React, { HTMLProps } from "react";

type CheckeredContainerProps = HTMLProps<HTMLElement>;

export const CheckeredContainer = ({
  className,
  children,
  ...props
}: CheckeredContainerProps) => (
  <section
    className={cx("bg-repeat bg-checkered bg-fixed", className)}
    {...props}
  >
    {children}
  </section>
);
