import { createContext, PropsWithChildren } from "react";

import { BestSellersEntries } from "shared/catalog/BestSellersCatalog/best-sellers-catalog.helper";

type BestSellersContextData = BestSellersEntries | undefined;

export const BestSellersContext =
  createContext<BestSellersContextData>(undefined);

type BestSellersContextProps = { entries: BestSellersEntries };

export const BestSellersContextProvider = ({
  children,
  entries,
}: PropsWithChildren<BestSellersContextProps>) => (
  <BestSellersContext.Provider value={entries}>
    {children}
  </BestSellersContext.Provider>
);
