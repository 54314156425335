import Carousel from "nuka-carousel";
import Marquee from "react-fast-marquee";

import { NewspaperApparition } from "app/home/PressFooter/NewspaperApparition";
import BfmBusinessGrey from "app/home/PressFooter/logo/bfm-business-grey.webp";
import BfmBusiness from "app/home/PressFooter/logo/bfm-business.webp";
import CapitalGrey from "app/home/PressFooter/logo/capital-grey.webp";
import Capital from "app/home/PressFooter/logo/capital.webp";
import ChallengesGrey from "app/home/PressFooter/logo/challenges-grey.webp";
import Challenges from "app/home/PressFooter/logo/challenges.webp";
import France2Grey from "app/home/PressFooter/logo/france2-grey.webp";
import France2 from "app/home/PressFooter/logo/france2.webp";
import LaTribuneGrey from "app/home/PressFooter/logo/la-tribune-greyed.webp";
import LaTribune from "app/home/PressFooter/logo/la-tribune.webp";
import LeFigaroGrey from "app/home/PressFooter/logo/le-figaro-grey.webp";
import LeFigaro from "app/home/PressFooter/logo/le-figaro.webp";
import LeParisienGrey from "app/home/PressFooter/logo/le-parisien-grey.webp";
import LeParisien from "app/home/PressFooter/logo/le-parisien.webp";
import LesEchosGrey from "app/home/PressFooter/logo/les-echos-grey.webp";
import LesEchos from "app/home/PressFooter/logo/les-echos.webp";
import MaddynessGrey from "app/home/PressFooter/logo/maddyness-grey.webp";
import Maddyness from "app/home/PressFooter/logo/maddyness.webp";
import { StaticImage } from "ui/StaticImage";

export function PressFooter() {
  const NEWSPAPER_APPARITIONS = [
    <NewspaperApparition
      key={1}
      logo={LaTribune}
      previewText={
        "mobile.club transforme l'usage des smartphones en offrant la possibilité de les louer plutôt que de les acheter."
      }
      link={
        "https://www.latribune.fr/supplement/louer-son-telephone-la-prochaine-revolution-929044.html"
      }
    />,
    <NewspaperApparition
      key={2}
      logo={LeFigaro}
      previewText={
        "La hausse des prix ralentit les achats, mais des startups comme mobile.club proposent des solutions de location innovantes."
      }
      link={
        "https://www.lefigaro.fr/secteur/high-tech/la-location-de-smartphones-en-plein-essor-20230901"
      }
    />,
    <NewspaperApparition
      key={3}
      logo={LeParisien}
      previewText={
        "mobile.club propose à la location les derniers modèles d’Apple ou de Samsung à des mensualités raisonnables."
      }
      link={
        "https://www.leparisien.fr/economie/consommation/louer-son-smartphone-peut-etre-une-bonne-affaire-face-a-la-flambee-des-prix-02-11-2023-NS7TIS4KV5GVRNK44XLAOB5AGI.php"
      }
    />,
    <NewspaperApparition
      key={4}
      logo={LesEchos}
      previewText={
        "Cette start-up spécialisée dans la location de smartphones vient de boucler une levée de fonds auprès de 130 business angels."
      }
      link={
        "https://www.lesechos.fr/start-up/portraits/damien-morin-le-jeune-sage-de-la-french-tech-1895846"
      }
    />,
    <NewspaperApparition
      key={5}
      logo={BfmBusiness}
      previewText={
        "Grâce aux services en ligne de mobile.club, il est désormais possible de louer des smartphones Apple à des prix abordables. "
      }
      link={
        "https://www.bfmtv.com/economie/replay-emissions/good-morning-business/damien-morin-mobile-club-mobile-club-propose-de-louer-un-telephone-via-un-abonnement-mensuel-11-01_VN-202301110088.html"
      }
    />,
    <NewspaperApparition
      key={6}
      logo={Maddyness}
      previewText={
        "Actuellement, mobile.club compte\n30 000 clients et prévoit d'en avoir \n50 000 en 2024, grâce à l'attrait de son offre."
      }
      link={
        "https://www.maddyness.com/2023/11/02/pourquoi-mobile-club-a-choisi-de-lever-7-millions-deuros-en-dette/"
      }
    />,
    <NewspaperApparition
      key={7}
      logo={Capital}
      previewText={
        "En réponse à la montée des prix, de nombreux Français optent pour la location de smartphones à des tarifs compétitifs."
      }
      link={
        "https://www.capital.fr/conso/face-a-la-flambee-des-prix-de-plus-en-plus-de-francais-preferent-louer-leur-smartphone-1477970"
      }
    />,
    <NewspaperApparition
      key={8}
      logo={France2}
      previewText={
        "Les smartphones, devenus indispensables, affichent des prix d'achat de plus en plus élevés, excepté chez mobile.club !"
      }
      link={
        "https://www.francetvinfo.fr/economie/pouvoir-achat/pouvoir-d-achat-l-essor-de-la-location-de-smartphones_6161727.html"
      }
    />,
    <NewspaperApparition
      key={9}
      logo={Challenges}
      previewText={
        "Nos tarifs varient de 19,90 euros à 56,90 euros mensuels, couvrant une large gamme : de l'iPhone 11 à l'iPhone 14 Pro Max."
      }
      link={
        "https://www.challenges.fr/club-entrepreneurs/mobile-club-la-start-up-qui-veut-democratiser-la-location-de-smartphones_872340"
      }
    />,
  ];

  const GREY_NEWSPAPER_LOGO = [
    LaTribuneGrey,
    LeFigaroGrey,
    LeParisienGrey,
    LesEchosGrey,
    BfmBusinessGrey,
    MaddynessGrey,
    CapitalGrey,
    France2Grey,
    ChallengesGrey,
  ];

  let greyFirstRow: JSX.Element[] = [];
  let greySecondRow: JSX.Element[] = [];

  GREY_NEWSPAPER_LOGO.forEach((logo, index) => {
    const logoDiv = (
      <div key={index} className="relative mx-3 mt-3 w-14 h-8 lg:mx-8 lg:w-24">
        <StaticImage
          src={logo}
          alt={"Logo newspaper grey"}
          layout={"fill"}
          objectFit={"contain"}
          className={"grow"}
        />
      </div>
    );

    if (index < 5) {
      greyFirstRow.push(logoDiv);
    } else {
      greySecondRow.push(logoDiv);
    }
  });

  return (
    <div
      style={{
        backgroundColor: "#FFF95F",
        borderTop: "1px solid",
        borderBottom: "1px solid",
      }}
      className="pt-8 pb-4 mt-8 lg:py-10 lg:mt-36"
    >
      <div className="lg:hidden">
        <Carousel
          defaultControlsConfig={{
            nextButtonClassName: "hidden",
            prevButtonClassName: "hidden",
            pagingDotsContainerClassName: "gap-2 press-carousel",
          }}
          className={"pb-8"}
        >
          {NEWSPAPER_APPARITIONS}
        </Carousel>
      </div>
      <div className="hidden lg:block">
        <Marquee
          pauseOnHover
          direction="right"
          speed={20}
          style={{ height: "320px" }}
        >
          {NEWSPAPER_APPARITIONS}
        </Marquee>
      </div>
      {greyFirstRow && (
        <div className="flex justify-center">{greyFirstRow}</div>
      )}
      {greySecondRow && (
        <div className="flex justify-center">{greySecondRow}</div>
      )}
    </div>
  );
}
