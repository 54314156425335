import Image from "next/image";
import Link from "next/link";

import { Button } from "ui/Button";
import { CheckeredContainer } from "ui/CheckeredContainer";
import { useI18n } from "utils/with-i18n.utils";

import { BenefitCard } from "./Card";

const securitySvg = "images/home/benefits/security.svg";
const cockadeSvg = "images/home/benefits/cockade.svg";
const deliveryFastSvg = "images/home/benefits/delivery-fast.svg";
const syncDevicesSvg = "images/home/benefits/sync-devices.svg";

export const Benefits = () => {
  const i18n = useI18n();

  return (
    <CheckeredContainer className="flex flex-col gap-10 items-center px-7 pb-10 w-full">
      <section className="flex flex-col items-center">
        <h2 className="text-4xl font-medium text-center md:text-6xl">
          {i18n.t("homepage.benefits.title")}
        </h2>
        <Image
          src="/images/home/with-mobile-club.svg"
          alt="avec mobile club"
          width={216}
          height={69}
        />
      </section>
      <p className="text-lg leading-7 text-center md:w-3/5">
        {i18n.t("homepage.benefits.description", {}, { html: true })}
      </p>
      <section className="flex flex-col gap-3 md:w-[85%]">
        <div className="flex flex-col gap-3 justify-center items-center lg:flex-row">
          <BenefitCard
            title={i18n.t(
              "homepage.benefits.security.title",
              {},
              { html: true },
            )}
            description={i18n.t(
              "homepage.benefits.security.description",
              {},
              { html: true },
            )}
            icon={securitySvg}
            iconAlt="icone bouclier"
          />
          <BenefitCard
            title={i18n.t(
              "homepage.benefits.cockade.title",
              {},
              { html: true },
            )}
            description={i18n.t(
              "homepage.benefits.cockade.description",
              {},
              { html: true },
            )}
            icon={cockadeSvg}
            iconAlt="icone medaille"
          />
        </div>
        <div className="flex flex-col gap-3 justify-center items-center lg:flex-row">
          <BenefitCard
            title={i18n.t(
              "homepage.benefits.delivery.title",
              {},
              { html: true },
            )}
            description={i18n.t(
              "homepage.benefits.delivery.description",
              {},
              { html: true },
            )}
            icon={deliveryFastSvg}
            iconAlt="icone transport"
          />
          <BenefitCard
            title={i18n.t("homepage.benefits.sync.title", {}, { html: true })}
            description={i18n.t(
              "homepage.benefits.sync.description",
              {},
              { html: true },
            )}
            icon={syncDevicesSvg}
            iconAlt="icone changement de telephone"
          />
        </div>
      </section>
      <Link href="/smartphone">
        <a aria-label="choisir un telephone">
          <Button className="text-lg font-medium">
            {i18n.t("homepage.benefits.button")}
          </Button>
        </a>
      </Link>
    </CheckeredContainer>
  );
};
