import cx from "classnames";
import { HTMLAttributes } from "react";

import ArrowRight from "app/home/PressFooter/logo/arrow-right.svg";

interface ArticlePreviewTextProps {
  previewText: string;
  link: string;
}

export const UncrawlableLink = ({
  href,
  children,
  className,
  ...props
}: { href: string } & HTMLAttributes<HTMLSpanElement>) => (
  <span
    onClick={() => window.open(href)}
    className={cx(className, "cursor-pointer")}
    {...props}
  >
    {children}
  </span>
);

export function ArticlePreviewText({
  previewText,
  link,
}: ArticlePreviewTextProps) {
  return (
    <div
      style={{
        maxWidth: "375px",
        maxHeight: "165px",
        position: "relative",
        whiteSpace: "pre-line",
      }}
      className="mt-4"
    >
      <svg
        preserveAspectRatio="xMaxYMid meet"
        viewBox="0 0 52 26"
        width="52"
        height="25"
        style={{
          position: "absolute",
          height: "25px",
          top: "-23.5px",
          left: "45px",
        }}
      >
        <polygon points="10,25 44,25 52,8" style={{ fill: "black" }} />
        <polygon points="0,25 35,25 45,0" style={{ fill: "black" }} />
        <polygon points="1,26 34,26 42,3" style={{ fill: "white" }} />
      </svg>
      <div
        style={{
          height: "100%",
          border: "1px solid",
          borderRadius: "10px",
          backgroundColor: "white",
          boxShadow: "5px 5px black",
        }}
        className="mb-9"
      >
        <div
          className="py-3 px-8"
          style={{
            width: "fit-content",
            height: "147px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <p>{previewText}</p>
          <UncrawlableLink
            style={{
              fontWeight: "bold",
              display: "flex",
              alignItems: "center",
            }}
            href={link}
            className="lg:justify-end"
          >
            Lire l'article{" "}
            <ArrowRight width={30} height={20} className="lg:pl-1" />
          </UncrawlableLink>
        </div>
      </div>
    </div>
  );
}
