export async function mapSeries<TItem, TResult>(
  arr: TItem[],
  fn: (item: TItem, idx: number) => Promise<TResult>,
) {
  let index = 0;
  const output = [];

  for (const item of arr) {
    const result = await fn(item, index++);
    output.push(result);
  }

  return output;
}
