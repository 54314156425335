import Link from "next/link";

import { AvailablePhoneCard } from "shared/catalog/AvailablePhoneCard";
import { BestSellersEntries } from "shared/catalog/BestSellersCatalog/best-sellers-catalog.helper";

type BestSellerItemProps = {
  item: NonNullable<BestSellersEntries>[number];
};

export const BestSellerItem = ({
  item: {
    isNew,
    isOutOfStock,
    minimumSubscriptionPrice,
    minimumUpfrontPrice,
    modelName,
    originalUpfrontPrice,
    picture,
    availableColors,
    slug,
  },
}: BestSellerItemProps) => (
  <Link href={`/smartphone${slug}`} shallow={false}>
    <a>
      <AvailablePhoneCard
        key={modelName}
        modelName={modelName}
        isNew={isNew}
        isOutOfStock={isOutOfStock}
        image={picture}
        colorCodes={availableColors}
        subscriptionPrice={minimumSubscriptionPrice}
        upfrontPrice={minimumUpfrontPrice}
        originalUpfrontPrice={originalUpfrontPrice}
      />
    </a>
  </Link>
);
