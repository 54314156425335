import { InferGetStaticPropsType } from "next";
import { ReactNode, Suspense } from "react";

import { PressFooter } from "app/home/PressFooter";
import { Reviews } from "app/home/Reviews";
import { KnowEachOtherSection } from "shared/KnowEachOtherSection";
import { AppLayout } from "shared/Layout";
import { fetchSettings } from "shared/SettingsProvider/settings.helper";
import { BestSellersContextProvider } from "shared/catalog/BestSellersCatalog/BestSellersContext";
import { getBestSellerEntries } from "shared/catalog/BestSellersCatalog/best-sellers-catalog.helper";
import { Banner } from "ui/Banner";
import { CACHE_ONE_HOUR } from "utils/config.utils";
import { fetchPages } from "utils/fetch-page.utilts";
import { useRemoveBlackFridayCookie } from "utils/useRemoveBlackFridayCookie";
import { withApp } from "utils/with-app.utils";
import { useI18n } from "utils/with-i18n.utils";
import { fetchTranslations } from "utils/with-i18n.utils";
import { fetchSEO, withSeo } from "utils/with-seo.utils";

import { Benefits } from "./home/Benefits";
import { BestSellers } from "./home/BestSellers";
import { Hero } from "./home/Hero";
import { HowItWorks } from "./home/HowItWorks";
import { RentVsBuy, Review } from "./home/RentVsBuy";

const HomePage = ({
  reviews,
  bestSellerEntries,
}: InferGetStaticPropsType<typeof getStaticProps>) => {
  const i18n = useI18n();

  useRemoveBlackFridayCookie();

  return (
    <>
      <Hero />
      <Suspense>
        <BestSellersContextProvider entries={bestSellerEntries}>
          <BestSellers />
        </BestSellersContextProvider>
        <Benefits />
        <HowItWorks />
        <Banner content={[i18n.t("marquee.tooSmartToBuy")]} direction="right" />
        <RentVsBuy />
        <PressFooter />
        <Reviews reviews={reviews} />
        <KnowEachOtherSection />
      </Suspense>
    </>
  );
};

HomePage.getLayout = (page: ReactNode, pageProps: any) => (
  <AppLayout withBanner {...pageProps}>
    {page}
  </AppLayout>
);

export async function getStaticProps() {
  const [reviews] = await fetchPages<Review[][]>("reviews");
  const translations = await fetchTranslations(["home", "marquee", "catalog"]);

  const seo = await fetchSEO("home");

  return {
    props: {
      bestSellerEntries: await getBestSellerEntries(),
      translations,
      reviews,
      seo,
      settings: await fetchSettings(),
    },
    revalidate: CACHE_ONE_HOUR,
  };
}

export default withApp(HomePage, [withSeo]);
